
.App {
    min-width: 300px;
}

.cursor-point {
    cursor: pointer;
}

.txt-center {
    text-align: center;
}

.fs-10 {
    font-size: 10px;
}
.fs-14 {
    font-size: 14px;
}
.fs-35 {
    font-size: 35px;
}
.fs-50 {
    font-size: 50px;
}
.fs-100 {
    font-size: 100px;
}

.grow1 {
    flex: 1;
}
.grow2 {
    flex: 2;
}
.grow3 {
    flex: 3;
}
.offClick {
    pointer-events: none;
}

.w40 {
    width: 40%;
}
.inpBut input, .inpBut textarea {
    padding: 5px 5px 5px 10px;
    border-radius: 5px 0 0 5px;
    border: none;
    background-color: #FFFFFF;
}

.inpBut input:focus {
    outline: none;
}

.inpBut button {
    border: none;
    border-radius: 0 5px 5px 0;
    padding: 5px 10px 5px 10px;
    background-color: #FFFFFF;
    transition: 0.2s ease;
}
.inpBut button:hover {
    background-color: #212529;
    color: #FFFFFF;
}


/*---------input && btn ---------*/
.inpFocus input{
    padding: 5px 10px;
}
.inpFocus input:focus {
    outline: none;
}
.inpFocus textarea:focus {
    outline: none;
}
.inpFocus button {
    border: 1px solid black;
    background-color: #FFFFFF;
    transition: 0.2s ease;
    padding: 5px 10px;
}
.inpFocus button:hover {
    background-color: #212529;
    color: #FFFFFF;
}

.minMax {
    min-width: 300px;
    max-width: 450px;
}

/*-----------------------------*/
.bdGrey {
    background-color: #f6f6f6;
}
.indexPage, .favoritePage {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
}

.post {
    max-width: 400px;
}

.post h2:hover {
    scale: 1.05 !important;
}

.post p:hover {
    scale: 1.05 !important;
}
.singlePostUsername:hover {
    scale: 1.05 !important;
}

.userImg {
    width: 200px;
}

.createPost {
    width: 500px;
}

.chatImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.timeStamp {
    font-size: 11px;
}
.delMess {
    font-size: 10px;
}

.newMess {
    position: absolute;
    top: -20px;
    right: -10px;
    font-size: 20px;
    color: #efb348;
}

@media screen and (width < 540px) {
    .post {
        max-width: 100%;
    }
    .createPost {
        width: 300px;
    }
    .messagePage{
        flex-direction: column !important;
    }
}